// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const images = require.context('../themes', true, /\.jpg$|\.jpeg$|\.png$|\.gif$|\.tiff$|\.ico$|\.eot$|\.otf$|\.ttf$|\.woff$|\.woff2$|\.svg$/)

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()


require("bootstrap/dist/css/bootstrap.css")
require("bootstrap-icons/font/bootstrap-icons.css")
require("aos/dist/aos.css")
require("glightbox/dist/css/glightbox.css")
require("swiper/swiper-bundle.css")
require("toastr/toastr.scss")
require("../themes/app/assets/css/variables.css")
require("../themes/app/assets/css/main.css")

window.$ = window.jQuery = window.jquery = require("jquery")
require("bootstrap/dist/js/bootstrap.bundle.js")
window.AOS = require("aos/dist/aos.js")
window.GLightbox = require("glightbox/dist/js/glightbox.js")
window.Isotope = require("isotope-layout/dist/isotope.pkgd.min.js")
window.Swiper = require("swiper/swiper-bundle.js")
window.toastr = require("toastr/toastr.js")
require("../themes/app/assets/js/main.js")